import {
  createStyles,
  Text,
  Avatar,
  Group,
  Button,
  TextInput,
} from "@mantine/core";
import { TbMessagePlus } from "react-icons/tb";
import { useForm } from "@mantine/form";

const useStyles = createStyles((theme) => ({
  body: {
    paddingLeft: 54,
    paddingTop: 5,
  },
  retry: {
    marginLeft: 54,
    fontWeight: "bold",
  },
  sendBtn: {
    backgroundColor: `${
      theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.dark[4]
    }`,
  },
}));

interface CommentItemProps {
  postedAt: string;
  body: string;
  author: {
    name: string;
    image?: string | null;
  };
}

const CommentItem = ({ postedAt, body, author }: CommentItemProps) => {
  const { classes } = useStyles();
  return (
    <div style={{ marginTop: "15px" }}>
      <Group>
        {!author?.image ? (
          <Avatar radius="xl" />
        ) : (
          <Avatar
            src={author.image}
            alt={author.name}
            radius="xl"
          />
        )}

        <div>
          <Text
            size="sm"
            weight={700}
          >
            {author.name}
          </Text>
          <Text
            size="xs"
            color="dimmed"
          >
            {postedAt}
          </Text>
        </div>
      </Group>
      <Text
        className={classes.body}
        size="sm"
      >
        {body}
      </Text>
      <Button
        className={classes.retry}
        variant="subtle"
        color="dark"
        size="xs"
        compact
      >
        Válasz
      </Button>
    </div>
  );
};

const CommentForm = () => {
  const { classes } = useStyles();
  const form = useForm({
    initialValues: { message: "" },
    validate: {
      message: (value) => (value.length < 2 ? true : null),
    },
  });
  return (
    <>
      <form onSubmit={form.onSubmit((values) => console.log(values))}>
        <Group
          position="apart"
          mt={15}
        >
          <TextInput
            icon={<TbMessagePlus />}
            placeholder="Hozzászólás írása"
            style={{ flex: 1 }}
            {...form.getInputProps("message")}
          />
          <Button
            className={classes.sendBtn}
            type="submit"
          >
            Küldés
          </Button>
        </Group>
      </form>
    </>
  );
};

const CommentList = () => {
  let comments = [
    {
      postedAt: "10 minutes ago",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      author: {
        name: "Test Elek",
        image: null,
      },
    },
    {
      postedAt: "10 minutes ago",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      author: {
        name: "Test Elek",
        image: null,
      },
    },
    {
      postedAt: "10 minutes ago",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      author: {
        name: "Test Elek",
        image: null,
      },
    },
  ];

  return (
    <>
      {comments.map((comment, index) => {
        return (
          <CommentItem
            key={index}
            postedAt={comment.postedAt}
            body={comment.body}
            author={{
              name: comment.author.name,
              image: comment.author.image,
            }}
          ></CommentItem>
        );
      })}
    </>
  );
};

export { CommentItem, CommentForm, CommentList };
