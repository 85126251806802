import { createStyles } from "@mantine/core";

const navbarStyles = createStyles((theme) => ({
  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logo: {
    fontFamily: "'Kdam Thmor Pro', sans-serif;",
    fontSize: 28,
    fontWeight: "bold",
    textShadow: `${
      theme.colorScheme === "dark" ? "2px 1px #fff" : "1px 1px #FA5251"
    }`,

    a: {
      color: `${theme.colorScheme === "dark" ? "#FA5251" : "#000"}`,
      textDecoration: "none",
    },
  },

  searchbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 292,
    color: "#adb5bd",
    border: "1px solid #dee2e6",
    padding: "5px 16px",
    background: "rgba(22, 24, 35, 0.06)",
    borderRadius: 92,
    cursor: "pointer",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },

  navBtn: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[5]
    }`,
    color: `${theme.colorScheme === "dark" ? theme.colors.gray[2] : "#000"}`,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      display: "none",
    },
  },

  loginAndProfileBtn: {
    cursor: "pointer",
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      display: "none",
    },
  },
}));

export default navbarStyles;
