import {
  Card,
  Avatar,
  Text,
  Badge,
  Group,
  Image,
  createStyles,
  Tooltip,
  Box,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface BlogPostProps {
  postedAt: string;
  postedAtFull: string;
  title: string;
  text?: string | null;
  author: {
    name: string;
    image?: string | null;
  };
  badges?: {
    title: string;
  }[];
}

const BlogPostBody = ({
  postedAt,
  postedAtFull,
  title,
  text,
  author,
  badges,
}: BlogPostProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Group position="apart">
        <Group spacing="xs">
          {!author?.image ? (
            <Avatar radius="xl" />
          ) : (
            <Avatar
              src={author.image}
              alt={author.name}
              radius="xl"
            />
          )}
          <div>
            <Text weight={700}>{author.name}</Text>
            <Tooltip
              label={postedAtFull}
              color="gray"
              position="bottom"
              withArrow
            >
              <Text
                size="xs"
                color="dimmed"
              >
                {postedAt}
              </Text>
            </Tooltip>
          </div>
        </Group>

        <Box
          sx={(theme) => ({
            textAlign: "right",
          })}
        >
          <Badge
            mb={5}
            size="sm"
            color="primary"
            variant="outline"
          >
            Blog bejegyzés
          </Badge>
          <Group spacing="xs">
            {badges?.map((item, index) => {
              return (
                <Badge
                  key={index}
                  size="sm"
                >
                  {item.title}
                </Badge>
              );
            })}
          </Group>
        </Box>
      </Group>
      <Card.Section className={classes.imageSection}>
        <Image
          src="https://i.imgur.com/ZL52Q2D.png"
          alt="Tesla Model S"
        />
      </Card.Section>
      <Text
        size="lg"
        weight={500}
        mt="md"
      >
        {title}
      </Text>
      <Text
        size="sm"
        color="dimmed"
        mt={5}
        mb={15}
      >
        {text}
      </Text>
    </>
  );
};

export default BlogPostBody;
