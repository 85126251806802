import {
  Header,
  Button,
  Container,
  Box,
  Group,
  Drawer,
  Global,
} from "@mantine/core";

import Link from "next/link";
import { DarkModeToggle } from "../components/layouts/darkmodeButton";
import { FaHome } from "react-icons/fa";
import { GoSearch } from "react-icons/go";

import { useSpotlight } from "@mantine/spotlight";
import navbarStyles from "./styles/navbarStyle";
import AddBtn from "./layouts/addButton";
import LoggedInBtn from "./layouts/loggedInBtn";

import { useSelector } from "react-redux";
import { selectUser } from "../lib/features/userSlice";

const useStyles = navbarStyles;

export function HeaderMenu() {
  const user = useSelector(selectUser);

  const { classes } = useStyles();

  const spotlight = useSpotlight();

  if (spotlight.opened) {
    console.log(spotlight.query);
  }

  return (
    <Header
      height={56}
      fixed={true}
    >
      <Global
        styles={(theme) => ({
          body: {
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : "#FAFAFA",
          },
        })}
      />
      <Container>
        <div className={classes.inner}>
          <div className={classes.logo}>
            <Link
              href="/"
              passHref
              legacyBehavior
            >
              <a>
                <span>{process.env.NEXT_PUBLIC_PAGE_NAME}</span>
              </a>
            </Link>
          </div>
          <Box
            className={classes.searchbox}
            onClick={spotlight.openSpotlight}
          >
            <span style={{ marginLeft: "5px" }}>Keresés az oldalon</span>
            <GoSearch></GoSearch>
          </Box>
          <Group spacing="xs">
            <Link href="/">
              <Button
                variant="outline"
                className={classes.navBtn}
              >
                <FaHome></FaHome>
              </Button>
            </Link>
            <AddBtn></AddBtn>
            <DarkModeToggle></DarkModeToggle>
            <div className={classes.loginAndProfileBtn}>
              <LoggedInBtn loggedIn={user.auth} />
            </div>
          </Group>
        </div>
      </Container>
    </Header>
  );
}
