import { useMantineColorScheme, Button } from "@mantine/core";
import { Sun, MoonStars } from "tabler-icons-react";
import { createStyles } from "@mantine/core";

const buttonStyles = createStyles((theme) => ({
  buttonRoot: {
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.gray[8] : theme.colors.gray[5]
    }`,
    color: `${theme.colorScheme === "dark" ? theme.colors.gray[2] : "#000"}`,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const useStyles = buttonStyles;

export function DarkModeToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { classes } = useStyles();

  const handleColorScheme = () => {
    if (localStorage.getItem("color-scheme") === "dark") {
      localStorage.setItem("color-scheme", "light");
      toggleColorScheme("light");
    } else {
      localStorage.setItem("color-scheme", "dark");
      toggleColorScheme("dark");
    }
  };

  return (
    <Button
      variant="outline"
      onClick={() => handleColorScheme()}
      className={classes.buttonRoot}
    >
      {colorScheme === "dark" ? <Sun size={20} /> : <MoonStars size={20} />}
    </Button>
  );
}
