import { Group, Button } from "@mantine/core";

const CategoryFilters = () => {
  return (
    <>
      <Group
        spacing="sm"
        position="center"
      >
        <Button
          color="gray"
          radius="lg"
          compact
        >
          Összes
        </Button>

        <Button
          color="blue.7"
          radius="lg"
          compact
        >
          Blog bejegyzések
        </Button>
        <Button
          color="indigo.8"
          radius="lg"
          compact
        >
          Kérdések
        </Button>
        <Button
          color="cyan.7"
          radius="lg"
          compact
        >
          Hirdetések
        </Button>
        <Button
          color="grape.7"
          radius="lg"
          compact
        >
          Mennyit ment?
        </Button>
      </Group>
    </>
  );
};

export default CategoryFilters;
