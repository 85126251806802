import {
  Card,
  Avatar,
  Text,
  Badge,
  Group,
  Image,
  createStyles,
  Tooltip,
  Box,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";

const useStyles = createStyles((theme) => ({
  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface adsPostProps {
  title: string;
  price: string | null;
  image?: {
    url: string | null;
    title?: string;
  };
  badges1?: {
    title: string;
  }[];
  badges2?: {
    title: string;
  }[];
}

const AdsPostBody = ({
  title,
  price,
  image,
  badges1,
  badges2,
}: adsPostProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Group position="apart">
        <Group spacing="xs">
          {badges1?.map((item, index) => {
            return (
              <Badge
                key={index}
                size="sm"
              >
                {item.title}
              </Badge>
            );
          })}
        </Group>
      </Group>
      <Card.Section className={classes.imageSection}>
        <Image
          src={image?.url}
          alt={image?.title}
          fit="contain"
          height={140}
          withPlaceholder
        ></Image>
      </Card.Section>
      <Text
        size="lg"
        weight={500}
        mt="md"
      >
        {title}
      </Text>
      <Box
        sx={(theme) => ({
          textAlign: "right",
          paddingTop: "10px",
        })}
      >
        <Group
          spacing="xs"
          position="center"
          pb={15}
        >
          {badges2?.map((item, index) => {
            return (
              <Badge
                key={index}
                size="md"
              >
                {item.title}
              </Badge>
            );
          })}
        </Group>
      </Box>
      <Card.Section
        withBorder
        inheritPadding
      >
        <Text
          size={26}
          weight={700}
          mt="sm"
          mb="sm"
          style={{ color: "#FA5251" }}
          align="center"
        >
          {price}
        </Text>
      </Card.Section>
    </>
  );
};

export default AdsPostBody;
