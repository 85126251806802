import {
  Card,
  Avatar,
  Text,
  Badge,
  Group,
  Button,
  Container,
  ActionIcon,
  Image,
  createStyles,
  Tooltip,
  Modal,
  useMantineTheme,
  SegmentedControl,
} from "@mantine/core";
import {
  BsHeart,
  BsBookmarkPlus,
  BsChatText,
  BsArrowRight,
} from "react-icons/bs";
import { useState } from "react";
import { CommentList, CommentForm } from "../Comment";
import BlogPostBody from "../cards/blogPostBody";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface BlogPostParam {
  postId: number;
}

const BlogPost = ({ postId }: BlogPostParam) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [tabValue, setTabValue] = useState("post");

  const handleChangeModal = (open: boolean, tab: string) => {
    setOpened(open);
    setTabValue(tab);
  };

  return (
    <>
      <Container
        size={470}
        px={0}
      >
        <Card
          withBorder
          radius="md"
          shadow="sm"
          className={classes.card}
        >
          <BlogPostBody
            postedAt="Ma"
            postedAtFull="2022. október 26., szerda, 14:22"
            title="5.3 minor release (September 2022)"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            author={{
              name: "tipushiba.hu",
              image:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80",
            }}
            badges={[
              { title: "Renault" },
              { title: "Scenic" },
              { title: "2005" },
            ]}
          />
          <Card.Section
            withBorder
            inheritPadding
            py="xs"
          >
            <Group position="apart">
              <ActionIcon
                variant="subtle"
                radius="md"
                size={36}
                onClick={() =>
                  showNotification({
                    title: "Mentve.",
                    message: "",
                    icon: <BsHeart size={16} />,
                    color: "red",
                  })
                }
              >
                <BsHeart size={20} />
              </ActionIcon>

              <ActionIcon
                variant="subtle"
                radius="md"
                size={36}
                onClick={() =>
                  showNotification({
                    title: "Mentve a könyvjelzők közé.",
                    message: "",
                    icon: <BsBookmarkPlus size={16} />,
                    color: "blue",
                  })
                }
              >
                <BsBookmarkPlus size={20} />
              </ActionIcon>

              <ActionIcon
                variant="subtle"
                radius="md"
                size={36}
                onClick={() => handleChangeModal(true, "comment")}
              >
                <BsChatText size={20} />
              </ActionIcon>
              <Button
                radius="md"
                style={{ flex: 1 }}
                color="gray"
                rightIcon={<BsArrowRight />}
                onClick={() => handleChangeModal(true, "post")}
              >
                Részletek
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </Container>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[4]
        }
        overlayOpacity={0.55}
        overlayBlur={4}
        size="md"
        title={
          <SegmentedControl
            value={tabValue}
            onChange={setTabValue}
            data={[
              { label: "Bejegyés", value: "post" },
              { label: "Hozzászólás", value: "comment" },
            ]}
          />
        }
      >
        <div style={{ display: tabValue == "post" ? "block" : "none" }}>
          <BlogPostBody
            postedAt="Ma"
            postedAtFull="2022. október 26., szerda, 14:22"
            title="5.3 minor release (September 2022)"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            author={{
              name: "tipushiba.hu",
              image:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80",
            }}
            badges={[
              { title: "Renault" },
              { title: "Scenic" },
              { title: "2005" },
            ]}
          />
        </div>
        <div style={{ display: tabValue == "comment" ? "block" : "none" }}>
          <CommentList></CommentList>
          <CommentForm></CommentForm>
        </div>
      </Modal>
    </>
  );
};

export default BlogPost;
