import { Button, Group, Divider, Anchor, ActionIcon } from "@mantine/core";

import { IconBrandTwitter, IconBrandFacebook } from "@tabler/icons";

import CategoryFilters from "./filter/categories";
import BrandFilter from "./filter/brands";
import FooterMenu from "./filter/foooter_menu";

import { createStyles } from "@mantine/core";

const rightFilterStyles = createStyles((theme) => ({
  carFilterItem: {
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.gray[8] : theme.colors.gray[4]
    }`,
    "&:hover": {
      borderColor: theme.colors.red[9],
    },
  },
  menuLinks: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

const useStyles = rightFilterStyles;

interface FooterMenuItemProps {
  links: { link: string; label: string }[];
}

const RightFilter = () => {
  const { classes } = useStyles();

  return (
    <>
      <div style={{ position: "sticky", top: 80 }}>
        <CategoryFilters></CategoryFilters>
        <BrandFilter></BrandFilter>

        <Divider my={25} />

        <FooterMenu></FooterMenu>

        <Group
          spacing={0}
          className={classes.social}
          position="right"
          noWrap
        >
          <ActionIcon size="lg">
            <IconBrandTwitter
              size={18}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandFacebook
              size={18}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </div>
    </>
  );
};

export default RightFilter;
