import type { NextPage } from "next";
import Head from "next/head";
import {
  Grid,
  Container,
  Stack,
  Tabs,
  Burger,
  Drawer,
  Divider,
} from "@mantine/core";
import { HeaderMenu } from "../components/navbar";
import RightFilter from "../components/layouts/rightFilter";
import BlogPost from "../components/layouts/cards/blogPost";
import QuestionPost from "../components/layouts/cards/questionPost";
import AdsPost from "../components/layouts/cards/adsPost";
import AdsPostMini from "../components/layouts/cards/adsPostMini";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";

import CategoryFilters from "../components/layouts/filter/categories";
import BrandFilter from "../components/layouts/filter/brands";
import FooterMenu from "../components/layouts/filter/foooter_menu";

import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../lib/features/userSlice";

const Main: NextPage = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  console.log("index.tsx", user);

  const matches = useMediaQuery("(max-width: 700px)");

  const [opened, setOpened] = useState(false);
  const title = opened ? "Close navigation" : "Open navigation";

  return (
    <>
      <Head>
        <title>drive2.hu</title>
        <meta
          name="description"
          content="Generated by create next app"
        />
      </Head>

      <HeaderMenu></HeaderMenu>

      <Container
        mt={75}
        pb={65}
      >
        <Grid>
          <Grid.Col span={matches ? 10 : 8}>
            <Tabs
              color="gray"
              variant="pills"
              radius="lg"
              defaultValue="tab1"
            >
              <Tabs.List position="center">
                <Tabs.Tab value="tab1">Feeds</Tabs.Tab>
                <Tabs.Tab value="tab2">Hirdetések</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel
                value="tab1"
                pt="xs"
              >
                <Stack>
                  <BlogPost postId={0}></BlogPost>
                  <QuestionPost postId={0}></QuestionPost>
                  <AdsPost postId={0}></AdsPost>
                </Stack>
              </Tabs.Panel>

              <Tabs.Panel
                value="tab2"
                pt="xs"
              >
                <Grid>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                  <Grid.Col
                    sm={12}
                    md={6}
                  >
                    <AdsPostMini postId={0}></AdsPostMini>
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>
            </Tabs>
          </Grid.Col>
          <Grid.Col span={matches ? 2 : 4}>
            {matches ? (
              <div style={{ width: "100%", textAlign: "right" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  title={title}
                />
                <Drawer
                  opened={opened}
                  onClose={() => setOpened(false)}
                  padding="xl"
                  size="xl"
                  position="right"
                >
                  <CategoryFilters></CategoryFilters>
                  <BrandFilter></BrandFilter>
                  <Divider my={25} />
                  <FooterMenu></FooterMenu>
                </Drawer>
              </div>
            ) : (
              <RightFilter></RightFilter>
            )}
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default Main;
