import { Divider, Group, Avatar, createStyles } from "@mantine/core";

const BrandsStyles = createStyles((theme) => ({
  carFilterItem: {
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.gray[8] : theme.colors.gray[4]
    }`,
    "&:hover": {
      borderColor: theme.colors.red[9],
    },
  },
}));

const useStyles = BrandsStyles;

const BrandFilter = () => {
  const { classes } = useStyles();

  return (
    <>
      <Divider
        my={25}
        label="Lista márkák szerint"
        labelPosition="center"
      />

      <Group position="center">
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_alfa.png"
          alt="Alfa Romeo"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_peugeot.png"
          alt="Peugeot"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_audi.png"
          alt="Audi"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_toyota.png"
          alt="Toyota"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_renault.png"
          alt="Renault"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_suzuki.png"
          alt="Suzuki"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_fiat.png"
          alt="Fiat"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_vw.png"
          alt="Volkswagen"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_mercedes.png"
          alt="Mercedes"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_skoda.png"
          alt="Skoda"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_seat.png"
          alt="Seat"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_volvo.png"
          alt="Volvo"
          radius="xl"
          className={classes.carFilterItem}
        />
        <Avatar
          component="a"
          href="#"
          src="https://tipushiba.hu/files/logo/l_nissan.png"
          alt="Nissan"
          radius="xl"
          className={classes.carFilterItem}
        />
      </Group>
    </>
  );
};

export default BrandFilter;
