import {
  Card,
  Avatar,
  Text,
  Badge,
  Group,
  Container,
  ActionIcon,
  createStyles,
  useMantineTheme,
  Modal,
  SegmentedControl,
  Box,
} from "@mantine/core";
import { BsHeart, BsBookmarkPlus, BsChatText } from "react-icons/bs";
import { useState } from "react";
import { CommentList, CommentForm } from "../Comment";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface QuestionPostParam {
  postId: number;
}

const QuestionPost = ({ postId }: QuestionPostParam) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Container
        size={470}
        px={0}
      >
        <Card
          withBorder
          radius="md"
          shadow="sm"
          className={classes.card}
        >
          <Text
            size="sm"
            color="dimmed"
            mt={5}
            mb={15}
            italic
          >
            Form context management, Switch, Grid and Indicator components
            improvements, new hook and 10+ other changes?
          </Text>
          <Card.Section
            withBorder
            inheritPadding
            py="xs"
          >
            <Box
              sx={(theme) => ({
                textAlign: "left",
              })}
            >
              <Group spacing="xs">
                <Badge size="sm">Renault</Badge>
                <Badge size="sm">Scanic</Badge>
                <Badge size="sm">2005</Badge>
              </Group>
              <Badge
                mt={5}
                size="sm"
                color="indigo.8"
                variant="outline"
              >
                Kérdés a közösséghez
              </Badge>
            </Box>
          </Card.Section>
          <Card.Section
            withBorder
            inheritPadding
            py="xs"
          >
            <Group position="apart">
              <Group>
                <ActionIcon
                  variant="subtle"
                  radius="md"
                  size={36}
                  onClick={() =>
                    showNotification({
                      title: "Mentve.",
                      message: "",
                      icon: <BsHeart size={16} />,
                      color: "red",
                    })
                  }
                >
                  <BsHeart size={20} />
                </ActionIcon>

                <ActionIcon
                  variant="subtle"
                  radius="md"
                  size={36}
                  onClick={() =>
                    showNotification({
                      title: "Mentve a könyvjelzők közé.",
                      message: "",
                      icon: <BsBookmarkPlus size={16} />,
                      color: "blue",
                    })
                  }
                >
                  <BsBookmarkPlus size={20} />
                </ActionIcon>

                <ActionIcon
                  variant="subtle"
                  radius="md"
                  size={36}
                  onClick={() => setOpened(true)}
                >
                  <BsChatText size={20} />
                </ActionIcon>
              </Group>

              <Avatar
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                radius="xl"
              />
            </Group>
          </Card.Section>
        </Card>
      </Container>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[4]
        }
        overlayOpacity={0.55}
        overlayBlur={4}
        size="md"
        title={
          <SegmentedControl
            value="comment"
            data={[{ label: "Hozzászólás", value: "comment" }]}
          />
        }
      >
        <div>
          <Card
            withBorder
            radius="md"
            className={classes.card}
          >
            <Text
              size="sm"
              color="dimmed"
              mt={5}
              mb={15}
              italic
            >
              Form context management, Switch, Grid and Indicator components
              improvements, new hook and 10+ other changes?
            </Text>
            <Card.Section
              withBorder
              inheritPadding
              py="xs"
            >
              <Group spacing="xs">
                <Badge size="sm">Renault</Badge>
                <Badge size="sm">Scanic</Badge>
                <Badge size="sm">2005</Badge>
              </Group>
            </Card.Section>
          </Card>
        </div>
        <CommentList></CommentList>
        <CommentForm></CommentForm>
      </Modal>
    </>
  );
};

export default QuestionPost;
