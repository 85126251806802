import {
  Card,
  Avatar,
  Text,
  Badge,
  Group,
  Image,
  createStyles,
  Tooltip,
  Box,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";

const useStyles = createStyles((theme) => ({
  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface adsPostProps {
  postedAt: string;
  postedAtFull: string;
  title: string;
  text?: string | null;
  price: string | null;
  author: {
    name: string;
    image?: string | null;
  };
  images?: {
    image: string | null;
    alt?: string;
  }[];
  badges1?: {
    title: string;
  }[];
  badges2?: {
    title: string;
  }[];
}

const AdsPostBody = ({
  postedAt,
  postedAtFull,
  title,
  text,
  price,
  author,
  images,
  badges1,
  badges2,
}: adsPostProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Group position="apart">
        <Group spacing="xs">
          {!author?.image ? (
            <Avatar radius="xl" />
          ) : (
            <Avatar
              src={author.image}
              alt={author.name}
              radius="xl"
            />
          )}
          <div>
            <Text weight={700}>{author.name}</Text>
            <Tooltip
              label={postedAtFull}
              color="gray"
              position="bottom"
              withArrow
            >
              <Text
                size="xs"
                color="dimmed"
              >
                {postedAt}
              </Text>
            </Tooltip>
          </div>
        </Group>
        <Group spacing="xs">
          {badges1?.map((item, index) => {
            return (
              <Badge
                key={index}
                size="sm"
              >
                {item.title}
              </Badge>
            );
          })}
        </Group>
      </Group>
      <Card.Section className={classes.imageSection}>
        {images?.length ? (
          <Carousel
            slideSize="70%"
            height={200}
            slideGap="md"
            withIndicators
            styles={{
              indicator: {
                width: 12,
                height: 4,
                transition: "width 250ms ease",
                backgroundColor: "#FA5251",
                "&[data-active]": {
                  width: 40,
                },
              },
            }}
          >
            {images?.map((item, index) => {
              return (
                <Carousel.Slide key={index}>
                  <Image
                    src={item.image}
                    alt={item.alt}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
        ) : null}
      </Card.Section>
      <Text
        size="lg"
        weight={500}
        mt="md"
      >
        {title}
      </Text>
      <Text
        size="sm"
        color="dimmed"
        mt={5}
      >
        {text}
      </Text>
      <Box
        sx={(theme) => ({
          textAlign: "right",
        })}
      >
        <Badge
          mb={5}
          size="sm"
          color="cyan.7"
          variant="outline"
        >
          Hirdetés
        </Badge>
        <Group
          spacing="xs"
          position="right"
          pb={15}
        >
          {badges2?.map((item, index) => {
            return (
              <Badge
                key={index}
                size="md"
              >
                {item.title}
              </Badge>
            );
          })}
        </Group>
      </Box>
      <Card.Section
        withBorder
        inheritPadding
      >
        <Text
          size={26}
          weight={700}
          mt="sm"
          mb="sm"
          style={{ color: "#FA5251" }}
          align="center"
        >
          {price}
        </Text>
      </Card.Section>
    </>
  );
};

export default AdsPostBody;
