import {
  Card,
  Group,
  Button,
  Container,
  ActionIcon,
  createStyles,
  Modal,
  useMantineTheme,
  SegmentedControl,
} from "@mantine/core";
import { BsHeart, BsBookmarkPlus, BsArrowRight } from "react-icons/bs";
import AdsPostBody from "../cards/adsPostBody";
import AdsPostBodyV2 from "../cards/adsPostBodyV2";
import { IoMdMail } from "react-icons/io";
import { BsTelephoneOutboundFill } from "react-icons/bs";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },
}));

interface AdsPostParam {
  postId: number;
}

const AdsPost = ({ postId }: AdsPostParam) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [tabValue, setTabValue] = useState("post");

  const handleChangeModal = (open: boolean, tab: string) => {
    setOpened(open);
    setTabValue(tab);
  };

  const ads = {
    postedAt: "Ma",
    postedAtFull: "2022. október 26., szerda, 14:22",
    title: "5.3 minor release (September 2022)",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    price: "1.290.000 Ft",
    author: {
      name: "Test Jakab",
      image:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80",
    },
    images: [
      {
        image: "https://i.imgur.com/ZL52Q2D.png",
        alt: "Tesla Model S",
      },
      {
        image: "https://i.imgur.com/ZL52Q2D.png",
        alt: "Tesla Model S",
      },
      {
        image: "https://i.imgur.com/ZL52Q2D.png",
        alt: "Tesla Model S",
      },
    ],
    badges1: [{ title: "Renault" }, { title: "Scenic" }, { title: "2005" }],
    badges2: [
      { title: "Dízel" },
      { title: "5 sebessége, manuális" },
      { title: "1968 m2" },
      { title: "290.000 km" },
    ],
  };

  return (
    <>
      <Container
        size={470}
        px={0}
      >
        <Card
          withBorder
          radius="md"
          shadow="sm"
          className={classes.card}
        >
          <AdsPostBody
            postedAt={ads.postedAt}
            postedAtFull={ads.postedAtFull}
            title={ads.title}
            text={ads.text}
            price={ads.price}
            author={{
              name: ads.author.name,
              image: ads.author.image,
            }}
            badges1={ads.badges1}
            badges2={ads.badges2}
            images={ads.images}
          />
          <Card.Section
            withBorder
            inheritPadding
            py="xs"
          >
            <Group position="apart">
              <ActionIcon
                variant="subtle"
                radius="md"
                size={36}
                onClick={() =>
                  showNotification({
                    title: "Mentve.",
                    message: "",
                    icon: <BsHeart size={16} />,
                    color: "red",
                  })
                }
              >
                <BsHeart size={20} />
              </ActionIcon>

              <ActionIcon
                variant="subtle"
                radius="md"
                size={36}
                onClick={() =>
                  showNotification({
                    title: "Mentve a könyvjelzők közé.",
                    message: "",
                    icon: <BsBookmarkPlus size={16} />,
                    color: "blue",
                  })
                }
              >
                <BsBookmarkPlus size={20} />
              </ActionIcon>

              <Button
                radius="md"
                style={{ flex: 1 }}
                color="gray"
                rightIcon={<BsArrowRight />}
                onClick={() => handleChangeModal(true, "post")}
              >
                Részletek
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </Container>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[4]
        }
        overlayOpacity={0.55}
        overlayBlur={4}
        size="md"
        title={
          <SegmentedControl
            value={tabValue}
            onChange={setTabValue}
            data={[
              { label: "Bejegyés", value: "post" },
              { label: "Üzenet a hirdetőnek", value: "message" },
            ]}
          />
        }
      >
        <div style={{ display: tabValue == "post" ? "block" : "none" }}>
          <AdsPostBodyV2
            postedAt={ads.postedAt}
            postedAtFull={ads.postedAtFull}
            title={ads.title}
            text={ads.text}
            price={ads.price}
            author={{
              name: ads.author.name,
              image: ads.author.image,
            }}
            badges1={ads.badges1}
            badges2={ads.badges2}
            images={ads.images}
          />
          <Group
            grow
            mt={15}
          >
            <Button
              color="green"
              leftIcon={<BsTelephoneOutboundFill />}
            >
              Hívás
            </Button>
            <Button
              color="blue"
              onClick={() => setTabValue("message")}
              leftIcon={<IoMdMail />}
            >
              Üzenet a hirdetőnek
            </Button>
          </Group>
        </div>
        <div
          style={{ display: tabValue == "message" ? "block" : "none" }}
        ></div>
      </Modal>
    </>
  );
};

export default AdsPost;
