import { createStyles, Anchor, Group } from "@mantine/core";

const footerMenuStyles = createStyles((theme) => ({
  menuLinks: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

const useStyles = footerMenuStyles;

interface FooterMenuItemProps {
  links: { link: string; label: string }[];
}

const FooterMenu = () => {
  const { classes } = useStyles();

  const links = [
    {
      label: "Névjegy",
      link: "#",
    },
    {
      label: "Súgó",
      link: "#",
    },
    {
      label: "Adatvédelem",
      link: "#",
    },
  ];

  const items = links.map((link) => (
    <Anchor<"a">
      color="dimmed"
      key={link.label}
      href={link.link}
      onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <>
      <Group
        className={classes.menuLinks}
        position="right"
      >
        {items}
      </Group>
    </>
  );
};

export default FooterMenu;
